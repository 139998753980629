import React from 'react'
import {useStaticQuery, graphql, Link} from "gatsby"

import Layout from '../components/layout'
import styles from './blog.module.scss'
import Head from '../components/head'


const BlogPage = () => {
  
  const data = useStaticQuery(graphql`
    {
      allContentfulPost(sort: {fields: date, order: DESC}) {
        nodes {
          title
          date(formatString: "DD.MM.YYYY.")
          slug
        }
      }
    } 
  `)

  const listItems = data.allContentfulPost.nodes.map(node =>
    <li className={styles.post} >
      <h3>{node.title}</h3>
      <p>{node.date} <Link to = {node.slug} >read more...</Link></p>
    </li>
  )

  return(
    <Layout>
      <Head title='Blog' />
      <h1>Blog Posts</h1>
      <ul className={styles.posts} >
        {listItems}
      </ul>
    </Layout>
  )

}

export default BlogPage